@import '../mixins.scss';
@import '../theme.scss';

.hidden-btn {
  box-sizing: border-box;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: inherit;
  &:disabled {
    cursor: auto;
    opacity: 0.6;
  }
  &.border {
    border: $border;
    border-radius: $border-radius;
    border-width: 2px;
    width: 30px;
    height: 30px;
    svg, img {
      width: 18px;
      height: auto;
    }
    &.md {
      width: 30px;
      height: 30px;
      svg, img {
        width: 18px;
      }
    }
    &.xl {
      width: 42px;
      height: 42px;
      svg, img {
        width: 24px;
      }
    }
  }
  &.sm {
    width: 24px;
    height: 24px;
    mat-icon {
      font-size: 21px;
      width: 21px;
      height: 21px;
    }
  }
  &.padding-0 {
    padding: 0;
  }
}

.active-btn {
  border: none;
  background-color: $blue100;
  @include icon-color(white);
}

.drag-btn {
  padding: 0;
  @extend .hidden-btn;
}
