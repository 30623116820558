@import "./mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800&display=swap');

$grey20: #f9f7f7;
$grey050: #f7f7f7;
$grey100: #e4e4e4;
$grey200: #c4c4c4;
$grey400: #888888;
$grey600: #020202;
$grey800: #2a2a2a;

$primary400: #28334a;

$blue100: #181b34;
$blue150: #3c6bbd;
$blue300: #0a2b65;

$red100: #ff2400;
$green50: #40dc7e;
$green100: #07b44c;
$orange100: #ffb519;
$orange200: #eda100;

$border: 1px solid $grey100;
$border-radius: 4px;
$box-shadow-card: 0px 2px 12px #dfe3eb;
$forms-atom-height: 38px;
$forms-atom-compact-height: 30px;
$forms-atom-font: 1.4rem;
$form-margin-between: 20px;
$regular-padding: 20px;
$fast-transition: 0.3s all;
$transition: 1s all;
$regular: 400;
$medium: 500;
$widgets-space: 30px;
$header-height: 68px;
$dropdown-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
  0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
$safari-height-gap: 15vh;

$animation-blink: blink 0.7s ease-in infinite;

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

* {
  font-family: "Nunito Sans", sans-serif;
}
