@mixin replace-padding($padding) {
    padding-left: $padding;
    margin-left: -$padding;
    padding-right: $padding;
    margin-right: -$padding;
}

@mixin icon-color($color) {
    svg {
        fill: $color;
        #surface1 {
            polygon, path, rect {
                fill: $color;
                stroke: none;
            }
        }
    }
}
@mixin field-shadow {
    border: none;
    border-radius: $border-radius;
    box-shadow: 0 0 0pt 1px $grey100;
}
@mixin field-shadow-active {
    outline: none;
    box-shadow: 0 0 0pt 1px $blue100;
}

// Animations
@mixin rotate-animation($speed) {
    -webkit-animation:spin $speed linear infinite;
    -moz-animation:spin $speed linear infinite;
    animation:spin $speed linear infinite;
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}