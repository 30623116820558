@import './app/@theme/core.scss';
@import './app/@theme/theme.scss';
@import './app/@theme/material-palette.scss';

.mat-mdc-select-disabled {
  .mat-mdc-select-arrow-wrapper {
    display: none;
  }

  .mat-mdc-select-trigger {
    user-select: initial !important;
    -webkit-user-select: initial !important;
  }
}

.table-row .row-cell span {
  font-size: 12px !important;
}

.mat-select-panel-overlay {
  width: auto !important;
  min-width: 30px;
  position: fixed;
}
