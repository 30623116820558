.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem ;
}
.mt-2 {
  margin-top: 0.5rem ;
}
.mt-3 {
  margin-top: 0.75rem ;
}
.mt-4 {
  margin-top: 1rem ;
}
.mt-5 {
  margin-top: 1.25rem ;
}
.mt-6 {
  margin-top: 1.5rem ;
}
.mt-7 {
  margin-top: 1.75rem;
}
.mt-8 {
  margin-top: 2rem;
}

.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.25rem ;
}
.ml-2 {
  margin-left: 0.5rem ;
}
.ml-3 {
  margin-left: 0.75rem ;
}
.ml-4 {
  margin-left: 1rem ;
}
.ml-5 {
  margin-left: 1.25rem ;
}
.ml-6 {
  margin-left: 1.5rem ;
}
.ml-7 {
  margin-left: 1.75rem;
}
.ml-8 {
  margin-left: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem ;
}
.mb-2 {
  margin-bottom: 0.5rem ;
}
.mb-3 {
  margin-bottom: 0.75rem ;
}
.mb-4 {
  margin-bottom: 1rem ;
}
.mb-5 {
  margin-bottom: 1.25rem ;
}
.mb-6 {
  margin-bottom: 1.5rem ;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.mb-8 {
  margin-bottom: 2rem;
}

.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.25rem ;
}
.mr-2 {
  margin-right: 0.5rem ;
}
.mr-3 {
  margin-right: 0.75rem ;
}
.mr-4 {
  margin-right: 1rem ;
}
.mr-5 {
  margin-right: 1.25rem ;
}
.mr-6 {
  margin-right: 1.5rem ;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}